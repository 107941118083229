import axios from 'axios';
import store from '@/store';
// import storage from '@helper/storage'
import { A_COMMON_CLEAR_TOKEN } from '@store/constance/constance.common';
import { baseUrl } from './config';

// create an axios instance
const service = axios.create({
  baseURL: baseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers.Accept = 'application/json';
    // console.log(storage.getToken());
    if (store.state.common.token) {
      config.headers['Login-Type'] = 'pc';
      config.headers['X-Auth-Token'] = store.state.common.token;
    }
    return config;
  },
  (error) => Promise.reject(error),

);

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201
      || response.status === 202 || response.status === 204) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(new Error(response.message || 'Error'));
  },
  (error) => {
    // console.log('err', error.response); // for debug
    if (error.response.status === 401) {
      store.dispatch(
        A_COMMON_CLEAR_TOKEN,
      );
    } else {
      console.log('服务器异常');
    }
    return Promise.reject(error);
  },
);

export default service;
