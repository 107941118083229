<template>
  <div :class="['apply-content',{'pc-apply-content':clientWidth>mobileWidth}]"
       v-show="showDialog"
       @click.stop="handleDialog">
    <div class="wapper" ref="applyWapper">
      <div class="item">
        <span>姓名</span>
        <a-input placeholder="请输入" v-model="form.name"/>
      </div>
      <div class="item">
        <span>性别</span>
        <a-select v-model="form.sex" placeholder="请选择性别">
          <a-select-option :value="0">男</a-select-option>
          <a-select-option :value="1">女</a-select-option>
        </a-select>
      </div>
      <div class="item">
        <span>电话</span>
        <a-input placeholder="请输入" v-model="form.phone" type="number" :maxLength="11"/>
      </div>
      <div class="item">
        <span>公司</span>
        <a-input placeholder="请输入" v-model="form.company"/>
      </div>
      <!-- <div class="btn" @click="toApply">立即申请</div> -->
      <div class="flex">
        <a-button block class="btn" @click="toApply" :loading="loading">立即申请</a-button>
      </div>
      <p class="tip line-p">您也可以拨打我们的电话进行申请</p>
      <p class="tel line-p">0411-82951884</p>
    </div>
  </div>
</template>

<script>
import { postApplyTrial } from '@api/api';

export default {
  name: 'ApplyContent',
  data() {
    return {
      showDialog: false,
      loading: false,
      form: {
        name: '',
        phone: '',
        company: '',
        sex: undefined,
      },
    };
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    async toApply() {
      if (this.loading) {
        return true;
      }
      if (!this.form.name) {
        return this.$message.error('请输入姓名！');
      }
      if (!this.form.sex && this.form.sex !== 0) {
        return this.$message.error('请选择性别！');
      }
      if (!this.form.phone) {
        return this.$message.error('请输入电话！');
      }
      if (!this.form.company) {
        return this.$message.error('请输入公司！');
      }
      this.loading = true;
      try {
        const data = await postApplyTrial(this.form);
        if (data.code !== 0) {
          return this.$message.error(data.msg);
        }
        this.$message.success('提交成功');
        this.resetForm();
        this.showDialog = false;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$message.error(error.msg);
        this.loading = false;
      }
      return true;
    },
    openDialog() {
      this.showDialog = true;
    },
    resetForm() {
      this.form = {
        name: '',
        phone: '',
        company: '',
      };
    },
    handleDialog(event) {
      const { applyWapper } = this.$refs;
      // 点击阴影部分
      if (!applyWapper.contains(event.target)) {
        this.showDialog = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.apply-content {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(51, 51, 51, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;

  .wapper {
    background-image: url('../assets/imgs/popup@2x.png');
    width: 335px;
    height: 505px;
    background-size: 100% 100%;
    margin-left: 15px;
    padding: 144px 0 0 0;

    .item {
      padding: 0 81px 0 56px;

      & span:first-child {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 22px;
        display: block;
        padding: 5px 0;
      }

      .ant-input {
        height: 26px;
      }

      .ant-select {
        width: 100%;
      }
    }

    .btn {
      background-image: url('../assets/imgs/btn_apply@2x.png');
      width: 140px;
      height: 59px;
      background-size: 100% 100%;
      margin: 0 111px 0 84px;
      line-height: 59px;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
      outline: none;
      border: none;
    }

    .line-p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      text-align: center;
      line-height: 17px;
    }

    .tip {
      color: rgba(0, 0, 0, 0.45);
      margin: 0 76px 0 49px;
      width: 212px;
    }

    .tel {
      font-weight: 600;
      color: #AF0000;
      letter-spacing: 1px;
      width: 134px;
      margin: 5px 112px 0 89px;
    }
  }
}

.pc-apply-content {
  .wapper {
    background-image: url('../assets/imgs/pcPopup@2x.png');
    width: 528px;
    height: 655px;
    padding: 213px 129px 0 93px;

    .item {
      padding: 0;

      & span:first-child {
        padding: 9px 0;
      }

      .ant-input {
        height: 32px;
      }
    }

    .btn {
      width: 207px;
      height: 78px;
      line-height: 78px;
      margin: 0 auto;
      outline: none;
      border: none;
    }
  }
}

.flex {
  display: flex;
  justify-content: center;
}
</style>
