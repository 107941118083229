import request from '@/helper/http/request';

// 申请
// eslint-disable-next-line import/prefer-default-export
export function postApplyTrial(data) {
  return request({
    url: 'https://esms.lngsyz.com/api/v1/feedback/submit',
    data,
    method: 'post',
  });
}
